<template>
  <div>
    <h4>Countries</h4>

    <div class="card mt-4 ">
      <div class="card-body">
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="countries"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          @changed="getData"
          @onPressSave="onPressSave"
          @editableInput="onChangeEditable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'name',
          sortable: true,
        },
        {
          title: 'continent',
          sortable: true,
        },
        {
          title: 'need_visa',
          mask: 'visa required',
          editable: 'checkbox',
        },
        {
          title: 'active',
          mask: 'show in filters',
          editable: 'checkbox',
        },
      ],
      actions: [
        {
          buttonClass: 'btn-success btn-sm', // use any class you want
          callback: 'onPressSave', // action you have to subscribe in the vue-table element
          tooltip: 'Save', // tooltip on hover
          icon: 'fas fa-save', // You can use any icon package, just install it before
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
    selCountry: null,
  }),
  computed: {
    ...mapGetters([
      'countries',
    ]),
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    ...mapActions([
      'getCountries',
      'updateCountry',
    ]),
    getData(evt = {}) {
      this.getCountries(evt).catch(this.showError);
    },
    onPressEdit(item) {
      this.selCountry = item;
    },
    resetTable() {
      this.$refs.vtable.init();
    },
    onChangeEditable(index, column, value) {
      const country = this.countries.data[index];
      country[column] = value;
      country.edited = true;
    },
    onPressSave(item) {
      if (item.edited) {
        this.updateCountry(item)
          .then(() => {
            this.$noty.success('Country updated succesfully');
            item.edited = false;
          })
          .catch(this.showError);
      } else {
        this.$noty.info('Edit some value to be able to save this item.');
      }
    },
  },
};
</script>

<style>

</style>
